




import { Vue, Component } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';

@Component
export default class MagicLink extends Vue {
    private async mounted(): Promise<void> {
        const token = this.$route.query.token;

        if (token) {
            await UserModule.setToken(token as string);
            await UserModule.getEmail();
        }

        this.$router.replace({ name: 'BattlesListPage' });
    }
}
